import Category from "../components/categrory/category";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";

export default function CategoryPage () {
    return(
        <>
        <Header/>
        <Category/> 
        <Footer/>
        </>
    )
    
}