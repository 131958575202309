export default function Preloader () {
    return(
        <>
        <div id="preloader">
        <div className="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
     
        </>
    )
    
}